import React from "react";
import {graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout"
import Seo from "../components/seo"
import LangSelector from "../components/LangSelector";
import locationSrc from '../images/svg/location.svg';
import phoneSrc from '../images/svg/phone.svg';
import mailSrc from '../images/svg/mail.svg';
import webSrc from '../images/svg/web.svg';
import ContactForm from "../components/ContactForm";
import { Title as DefaultTitle } from '../components/common/topPanel';
import DefaultContentWrapper from '../components/common/contentWrapper';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import logoSrc from '../images/logo-realspektrum.png';
import { RECAPTCHA_SITE_KEY } from "../config";

const ContentWrapper = styled(DefaultContentWrapper)`
  @media(min-width: 768px) {
    padding-left: 0;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  margin-right: 10px;
`;

const LocationIcon = styled(locationSrc)`
  width: 20px;
  height: auto;
`;

const PhoneIcon = styled(phoneSrc)`
  width: 24px;
  height: auto;
`;

const MailIcon = styled(mailSrc)`
  width: 24px;
  height: auto;
`;

const WebIcon = styled(webSrc)`
  width: 24px;
  height: auto;
  fill: ${({theme}) => theme.colors.primary};
`;

const Content = styled.div``;

const Section = styled.div`
  position: relative;
  /* height: calc(100vh - 52px); */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 70px 1.5rem;
  padding-bottom: 100px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.60;
    /* z-index: 1; */
  }

  .panel-image {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  @media(min-width: 768px) {
    padding-top: 120px;
  }

  @media(min-width: 1400px) {
    padding: 0;
    padding-top: 220px;
    padding-bottom: 100px;
    min-height: calc(100vh - 52px);
  }
`;

const Title = styled(DefaultTitle)`
  padding-top: 30px;
  padding-bottom: 50px;

  @media(min-width: 768px) {
    padding-top: 0;
    padding-bottom: 100px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;

  p {
    color: #fefefe;
    line-height: 1.5rem;
  }

  @media(min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    max-width: 1000px;
  }
`;

const Name = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #fefefe;
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  a, a:hover {
    color: #fefefe;
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }
`;

const Item = styled.div`
`;

const Info = styled.div`
  padding-right: 1.5rem;

  ${Item}:first-child {
    margin-bottom: 40px;
  }

  @media(min-width: 768px) {
    ${Item}:first-child {
    margin-bottom: 80px;
  }
  }
`;

const Text = styled.p`
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #fff;
  margin-bottom: 0.875rem;
  position: relative;
  text-align: left;

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const FormWrapper = styled.div`

  ${Text} {
    margin-bottom: 0;
  }

  @media(min-width: 768px) {
    ${Subtitle} {
      margin-bottom: 0.875rem;
    }

    ${Text} {
      margin-bottom: 2.25rem;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-bottom: 1rem;
`;

const WebLink = styled.a`
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
`;


const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('seo.contact.title')} />
      <Content>
        <Section>
          <StaticImage src="../images/img-bg.jpg" className="panel-image" placeholder="blurred" alt={t('photo_alt')} />
          <LangSelector />
          <ContentWrapper>
            <Title as="h1">{t('contact.title')}</Title>
            <Grid>
              <Info>
                <Item>
                  <Subtitle as="h2">{t('contact.developer')}</Subtitle>
                  <Name>Bydlení Pasohlávky s.r.o.</Name>
                  <InfoText>
                    <IconWrapper>
                      <LocationIcon />
                    </IconWrapper>
                      <p>Makovského náměstí 3147/2, Žabovřesky<br />616 00 Brno</p>
                  </InfoText>
                  <InfoText>
                    <p>
                      IČO: 07729715<br />
                      Spisová značka: C&nbsp;109893 vedená u&nbsp;Krajského soudu v&nbsp;Brně
                    </p>
                  </InfoText>
                </Item>
                <Item>
                  <Subtitle as="h2">{t('contact.seller')}</Subtitle>
                  <Logo src={logoSrc} alt="Logo REAL SPEKTRUM, a.s." />
                  <Name>REAL SPEKTRUM, a.s.</Name>
                  <InfoText>
                    <IconWrapper>
                      <PhoneIcon />
                    </IconWrapper>
                    <p>
                      <a href="tel:+420800800099">
                        +420 800 800 099
                      </a>
                    </p>
                  </InfoText>
                  <InfoText>
                    <IconWrapper>
                      <MailIcon />
                    </IconWrapper>
                    <p>
                      <a href="mailto:rs@realspektrum.cz">rs@realspektrum.cz</a>
                    </p>
                  </InfoText>
                  <InfoText>
                    <IconWrapper>
                      <WebIcon />
                    </IconWrapper>
                    <p>
                      <WebLink href="https://www.realspektrum.cz/" target="_blank" rel="noreferrer">realspektrum.cz</WebLink>
                    </p>
                  </InfoText>
                </Item>
              </Info>
              <FormWrapper>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
                  <Subtitle as="h2">{t('contact.contact_form')}</Subtitle>
                  <Text>{t('contact.contact_form_message')}</Text>
                  <ContactForm elementId="contact-form" />
                </GoogleReCaptchaProvider>
              </FormWrapper>
            </Grid>
          </ContentWrapper>
        </Section>
      </Content>
    </Layout>
  )
}

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
